import _objectSpread from "D:/Development/Laravel/genic-drive-updated/frontend/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.json.stringify.js";
import { QUERY, GET, PATCH } from "@/core/services/store/request.module";
import { SEARCH_ENABLED, SEARCH_TITLE } from "@/core/services/store/common.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ShareWithPeople from "@/view/components/ShareWithPeople";
import DeleteConfirmation from "@/view/components/DeleteConfirmation";
import RestoreConfirmation from "@/view/components/RestoreConfirmation";
import EmptyBinConfirmation from "@/view/components/EmptyBinConfirmation";
import RenameDialog from "@/view/components/RenameDialog";
import ShowValue from "@/view/components/ShowValue";
import MoveDialog from "@/view/components/MoveDialog";
import LockDialog from "@/view/components/LockDialog";
import UnlockDialog from "@/view/components/UnlockDialog";
import UnlockFolderDialog from "@/view/components/UnlockFolderDialog";
import { find, toSafeInteger, last, head, toString, cloneDeep } from "lodash";
import { EventBus } from "@/core/event-bus/event.bus";
import { AES, enc } from "crypto-js";
import ObjectPath from "object-path";
import KTTopSearchbar from "@/view/components/DriveSearchBar.vue";
import DatePicker from "@/view/components/DatePicker";
export default {
  name: "tag-listing",
  data: function data() {
    return {
      tab: "detail",
      endpoint: null,
      selected: null,
      file_log: [],
      loading: false,
      log_loading: true,
      detail_loading: false,
      detail_drawer: false,
      lock_dialog: false,
      unlock_dialog: false,
      move_dialog: false,
      rename_dialog: false,
      delete_dialog: false,
      share_dialog: false,
      permanent_delete: 0,
      show_context_menu: false,
      menu_x: null,
      menu_y: null,
      date_range: {
        start_date: null,
        end_date: null
      },
      folder_unlock: {},
      folder_unlock_dialog: false,
      filter: {
        "linked-id": 0,
        "page-no": 1,
        "page-limit": 50,
        search: null,
        "sort-column": null,
        "sort-order": null,
        "start-date": null,
        "end-date": null
      },
      pagination: {
        total_rows: null,
        total_pages: null,
        current_page: null,
        offset: null,
        page_limit: null
      },
      rows: [],
      to_be_delete: {
        uuid: null,
        type: null,
        name: null,
        created: {
          datetime: null
        }
      },
      to_be_rename: {
        uuid: null,
        type: null,
        name: null,
        created: {
          datetime: null
        }
      },
      to_be_move: {
        uuid: null,
        type: null,
        name: null,
        created: {
          datetime: null
        }
      },
      to_be_lock: {
        uuid: null,
        type: null,
        name: null,
        created: {
          datetime: null
        }
      },
      to_be_unlock: {
        uuid: null,
        type: null,
        name: null,
        created: {
          datetime: null
        }
      },
      to_be_restore: {
        uuid: null,
        type: null,
        name: null,
        created: {
          datetime: null,
          user: null
        },
        deleted: {
          datetime: null,
          user: null
        }
      },
      empty_bin_dialog: false,
      restore_dialog: false,
      default_context_menu: [],
      context_menu: [],
      temp_file: {},
      d_focus: false,
      file: {
        owner: {},
        shared: [],
        description: null
      },
      pre_select: {},
      open_timeout: null,
      open_timeout_limit: 200,
      route_timeout: null,
      route_timeout_limit: 500,
      linked_uuid: null,
      file_description: null,
      access_permission: []
    };
  },
  components: {
    "date-picker": DatePicker,
    "kt-top-search-bar": KTTopSearchbar,
    "show-value": ShowValue,
    "rename-dialog": RenameDialog,
    "move-dialog": MoveDialog,
    "lock-dialog": LockDialog,
    "unlock-dialog": UnlockDialog,
    "unlock-folder-dialog": UnlockFolderDialog,
    "share-with-people": ShareWithPeople,
    "delete-dialog": DeleteConfirmation,
    "restore-dialog": RestoreConfirmation,
    "empty-bin-dialog": EmptyBinConfirmation
  },
  watch: {
    $route: function $route() {
      this.$store.dispatch(SET_BREADCRUMB, [{
        dashboard: true,
        disabled: false,
        id: 0,
        text: "Tag",
        uuid: null
      }]);
      this.get_rows();
    }
  },
  methods: {
    redirect_ip: function redirect_ip(ip) {
      var url = "https://whatismyipaddress.com/ip/".concat(ip);
      window.open(url, "_blank");
    },
    focus_description: function focus_description() {
      var _this2 = this;

      this.d_focus = true;
      this.$nextTick(function () {
        _this2.$refs["file-description"].$refs["input-text-area"].$refs["input"].focus();
      });
    },
    update_description: function update_description() {
      this.d_focus = false;
      this.$store.dispatch(PATCH, {
        url: "files/".concat(this.selected, "/description"),
        data: {
          description: this.file_description
        }
      }).then(function (response) {
        console.log({
          response: response
        });
      }).catch(function (error) {
        console.log(error);
      });
    },
    clear_filter: function clear_filter(param) {
      this.date_range[param] = null;
    },
    filter_rows: function filter_rows() {
      console.log("filter rows");
    },
    get_file_log: function get_file_log() {
      var _this3 = this;

      this.log_loading = true;
      this.file_log = [];
      this.$store.dispatch(GET, {
        url: "files/".concat(this.selected, "/log")
      }).then(function (response) {
        _this3.file_log = response;
      }).catch(function (error) {
        console.log(error);
      }).finally(function () {
        _this3.log_loading = false;
      });
    },
    get_file_detail: function get_file_detail() {
      var _this = this;

      _this.detail_loading = true;
      _this.access_permission = [];
      _this.file_description = null;
      _this.file = new Object({
        owner: {},
        shared: []
      });
      return new Promise(function (resolve, reject) {
        if (!_this.selected) {
          reject("Invalid Request");
        } else {
          _this.$store.dispatch(GET, {
            url: "files/".concat(_this.selected, "/detail")
          }).then(function (response) {
            _this.file = response;
            _this.file_description = cloneDeep(response.description);
            _this.access_permission = cloneDeep(response.shared);

            _this.$nextTick(function () {
              resolve(response);
            });
          }).catch(function (error) {
            reject(error);
          }).finally(function () {
            _this.detail_loading = false;
          });
        }
      });
    },
    open_detail_drawer: function open_detail_drawer() {
      var _this4 = this;

      this.get_file_detail().then(function () {
        _this4.detail_drawer = true;
      }).catch(function (error) {
        console.log({
          error: error
        });
      });
      /*clearTimeout(_this.timeout);
      _this.timeout = setTimeout(function() {
      	
      }, _this.timeout_limit)*/
    },
    open_lock_dialog: function open_lock_dialog() {
      this.to_be_lock = find(this.rows, {
        uuid: this.selected
      });

      if (this.to_be_lock) {
        this.lock_dialog = true;
      }
    },
    open_unlock_dialog: function open_unlock_dialog() {
      this.to_be_unlock = find(this.rows, {
        uuid: this.selected
      });

      if (this.to_be_unlock) {
        this.unlock_dialog = true;
      }
    },
    open_share_dialog: function open_share_dialog() {
      var _this5 = this;

      this.get_file_detail().then(function () {
        _this5.share_dialog = true;
      }).catch(function (error) {
        console.log({
          error: error
        });
      });
    },
    open_restore_dialog: function open_restore_dialog() {
      this.to_be_restore = find(this.rows, {
        uuid: this.selected
      });

      if (this.to_be_restore) {
        this.restore_dialog = true;
      }
    },
    restore_success: function restore_success() {
      this.to_be_restore = new Object({
        uuid: null,
        type: null,
        name: null,
        created: {
          datetime: null,
          user: null
        },
        deleted: {
          datetime: null,
          user: null
        }
      });
      EventBus.$emit("refresh-listing", true);
      EventBus.$emit("refresh-storage", true);
    },
    empty_bin_success: function empty_bin_success() {
      EventBus.$emit("refresh-listing", true);
      EventBus.$emit("refresh-storage", true);
    },
    open_delete_dialog: function open_delete_dialog() {
      this.to_be_delete = find(this.rows, {
        uuid: this.selected
      });

      if (this.to_be_delete) {
        this.delete_dialog = true;
      }
    },
    delete_success: function delete_success() {
      this.to_be_delete = new Object({
        uuid: null,
        type: null,
        name: null,
        created: {
          datetime: null
        }
      });
      EventBus.$emit("refresh-listing", true);
      EventBus.$emit("refresh-storage", true);
    },
    open_rename_dialog: function open_rename_dialog() {
      var tag = find(this.rows, {
        uuid: this.selected
      });

      if (tag && tag.uuid) {
        this.to_be_rename = {
          uuid: tag.uuid,
          type: null,
          name: tag.tag_name,
          created: tag.created
        };

        if (this.to_be_rename) {
          this.rename_dialog = true;
        }
      }
    },
    rename_success: function rename_success() {
      this.to_be_rename = new Object({
        uuid: null,
        type: null,
        name: null,
        created: {
          datetime: null
        }
      });
      EventBus.$emit("refresh-listing", true);
      EventBus.$emit("refresh-storage", true);
    },
    update_color: function update_color(param) {
      var _this6 = this;

      this.$store.dispatch(PATCH, {
        url: "tag-listing/".concat(this.selected, "/color"),
        data: {
          hex: param
        }
      }).then(function () {
        EventBus.$emit("refresh-listing", true);
        EventBus.$emit("refresh-storage", true);
      }).catch(function (error) {
        console.log({
          error: error
        });
      }).finally(function () {
        // console.log({param})
        _this6.show_context_menu = false;
      });
    },
    context_menu_dialog: function context_menu_dialog(e, row) {
      var _this7 = this;

      e.preventDefault();

      if (this.loading) {
        return false;
      }

      this.show_context_menu = false;
      this.menu_x = e.clientX;
      this.menu_y = e.clientY;
      this.$nextTick(function () {
        _this7.select_row(row);

        _this7.show_context_menu = true;
      });
    },
    init_context_menu: function init_context_menu() {
      this.context_menu = []; // this.default_context_menu;

      for (var i = 0; i < this.default_context_menu.length; i++) {
        this.context_menu.push(this.default_context_menu[i]);
      }
    },
    do_menu_action: function do_menu_action(action) {
      switch (action) {
        case "share":
          this.open_share_dialog();
          break;

        case "move":
          this.open_move_dialog();
          break;

        case "add-to-starred":
          this.update_starred();
          break;

        case "remove-from-starred":
          this.update_starred();
          break;

        case "rename":
          this.open_rename_dialog();
          break;

        case "change-color":
          this.open_color_dialog();
          break;

        case "view-detail":
          this.open_detail_drawer();
          break;

        case "lock":
          this.open_lock_dialog();
          break;

        case "un-lock":
          this.open_unlock_dialog();
          break;

        case "download":
          this.download_file();
          break;

        case "restore":
          this.open_restore_dialog();
          break;

        case "remove":
          this.open_delete_dialog();
          break;
      }
    },
    select_row: function select_row(row, force) {
      var _this = this;

      if (!force) {
        if (_this.loading) {
          return false;
        }
      }

      if (!row) {
        return false;
      }

      _this.$nextTick(function () {
        _this.init_context_menu(row);
      });

      _this.temp_file = row;
      _this.selected = row.uuid;
    },
    load_child_folder: function load_child_folder(param) {
      var _this8 = this;

      this.filter.search = null;
      var folder_id = ObjectPath.get(param, "query.folder");

      if (folder_id) {
        var decrypted = AES.decrypt(folder_id, this.$enc_key);
        var linked_id = toSafeInteger(JSON.parse(decrypted.toString(enc.Utf8)));

        if (linked_id) {
          this.filter["linked-id"] = linked_id;
          this.$nextTick(function () {
            _this8.get_rows();
          });
        }
      } else {
        this.filter["linked-id"] = 0;
        this.$nextTick(function () {
          _this8.get_rows();
        });
      }
    },
    breadcrumb_route: function breadcrumb_route(row) {
      var _this9 = this;

      if (row.dashboard) {
        var route = {
          name: this.endpoint,
          query: {
            t: new Date().getTime()
          }
        };
        this.$router.replace(route).then(function () {
          _this9.get_rows();
        });
        return false;
      }

      if (this.filter["linked-id"] == row.id) {
        return false;
      }

      this.filter["page-no"] = 1;
      var folder_id = AES.encrypt(JSON.stringify(row.id), this.$enc_key).toString();
      var folder_uuid = AES.encrypt(JSON.stringify(row.uuid), this.$enc_key).toString();
      this.$router.replace({
        name: this.endpoint,
        query: {
          t: new Date().getTime(),
          folder: folder_id,
          fuuid: folder_uuid
        }
      }).then(function () {
        _this9.get_rows();
      });
    },
    open_folder: function open_folder(row) {
      if (this.loading) {
        return false;
      }

      this.$router.push({
        name: "tag-detail",
        params: {
          uuid: row.uuid
        },
        query: {
          t: new Date().getTime()
        }
      });
    },
    update_pagination: function update_pagination(param) {
      var _this10 = this;

      this.$router.push({
        name: this.endpoint,
        query: _objectSpread(_objectSpread({}, this.$route.query), {}, {
          t: new Date().getTime(),
          "page-no": param
        })
      }).then(function () {
        _this10.get_rows();
      });
    },
    get_rows: function get_rows() {
      var _this11 = this;

      if (this.loading) {
        return false;
      }

      this.loading = true;
      this.filter = _objectSpread({
        "linked-id": this.filter["linked-id"],
        "page-no": this.filter["page-no"],
        "page-limit": this.filter["page-limit"],
        search: this.filter["search"],
        "sort-column": this.filter["sort-column"],
        "sort-order": this.filter["sort-order"],
        "start-date": this.filter["start-date"],
        "end-date": this.filter["end-date"]
      }, this.$route.query);
      this.init_linked();
      this.filter["page-no"] = ObjectPath.get(this.$route, "query.page-no") || 1;
      this.filter["page-limit"] = ObjectPath.get(this.$route, "query.page-limit") || 50;
      this.filter["search"] = ObjectPath.get(this.$route, "query.search");
      this.filter["start-date"] = ObjectPath.get(this.$route, "query.start-date");
      this.filter["end-date"] = ObjectPath.get(this.$route, "query.end-date");
      var tag = ObjectPath.get(this.$route, "query.tag");

      if (tag) {
        this.filter["user"] = undefined;
        this.filter["tag"] = tag.substring(1);
      }

      var user = ObjectPath.get(this.$route, "query.user");

      if (user) {
        this.filter["tag"] = undefined;
        this.filter["user"] = user.substring(1);
      }

      if (this.filter["search"]) {
        this.filter["user"] = undefined;
        this.filter["tag"] = undefined;
      }

      this.$store.dispatch(QUERY, {
        url: "tag-listing",
        data: this.filter
      }).then(function (response) {
        _this11.$store.dispatch(SET_BREADCRUMB, response["breadcrumb"]);

        localStorage.setItem("prev-id", response["linked-id"]);
        _this11.rows = response.rows;
        _this11.pre_select = ObjectPath.get(response, "rows.0", null);
        _this11.filter["linked-id"] = response["linked-id"];
        _this11.default_context_menu = response["context-menu"];
        var last_breadcrumb = last(response["breadcrumb"]);

        if (last_breadcrumb) {
          _this11.$store.dispatch(SEARCH_TITLE, last_breadcrumb.text);
        }

        _this11.pagination.total_rows = response["total"];
        _this11.pagination.total_pages = Math.ceil(response["total"] / response["page-limit"]);
        _this11.pagination.current_page = response["page-no"];
        _this11.pagination.offset = response["offset"];
        _this11.pagination.page_limit = response["page-limit"];

        _this11.$nextTick(function () {
          _this11.select_row(_this11.pre_select, true);
        });
      }).catch(function (error) {
        console.log({
          error: error
        });
      }).finally(function () {
        if (_this11.rows.length < 1) {
          _this11.detail_loading = false;
          _this11.detail_drawer = false;
        }

        _this11.reset_all();

        _this11.$nextTick(function () {
          var scrollableDiv = document.getElementById("scrollable-div");
          scrollableDiv.scroll({
            top: 0,
            behavior: "smooth"
          });
        });
      });
    },
    reset_all: function reset_all() {
      var _this = this;

      _this.tab = "detail";
      _this.selected = null;
      _this.loading = false;
      _this.log_loading = true;
      _this.move_dialog = false;
      _this.rename_dialog = false;
      _this.delete_dialog = false;
      _this.share_dialog = false;
      _this.permanent_delete = 0;
      _this.show_context_menu = false;
      _this.menu_x = null;
      _this.menu_y = null;
      _this.to_be_delete = {
        uuid: null,
        type: null,
        name: null,
        created: {
          datetime: null
        }
      };
      _this.to_be_rename = {
        uuid: null,
        type: null,
        name: null,
        created: {
          datetime: null
        }
      };
      _this.to_be_move = {
        uuid: null,
        type: null,
        name: null,
        created: {
          datetime: null
        }
      };
      _this.access_permission = [];
      _this.file_description = null;
      _this.file = {
        owner: {},
        shared: []
      };
      var prev_folder_id = toSafeInteger(localStorage.getItem("prev-id"));

      if (prev_folder_id) {
        var prev_folder = find(_this.rows, {
          id: prev_folder_id
        });

        if (prev_folder) {
          _this.selected = prev_folder.uuid;

          _this.init_context_menu(prev_folder);
        } else {
          if (_this.pre_select) {
            _this.selected = _this.pre_select.uuid;

            _this.init_context_menu(_this.pre_select);
          } else {
            _this.selected = _this.linked_uuid;
          }
        }
      } else {
        if (_this.pre_select) {
          _this.selected = _this.pre_select.uuid;

          _this.init_context_menu(_this.pre_select);
        } else {
          _this.selected = _this.linked_uuid;
        }
      }
    },
    init_linked: function init_linked() {
      this.linked_uuid = null;
      var fuuid = ObjectPath.get(this.$route, "query.fuuid", null);

      if (fuuid) {
        var decrypted = AES.decrypt(fuuid, this.$enc_key);
        var linked_uuid = toString(JSON.parse(decrypted.toString(enc.Utf8)));

        if (linked_uuid) {
          this.linked_uuid = linked_uuid;
        }
      }

      this.filter["linked-id"] = 0;
      var folder_id = ObjectPath.get(this.$route, "query.folder", null);

      if (folder_id) {
        var _decrypted = AES.decrypt(folder_id, this.$enc_key);

        var linked_id = toSafeInteger(JSON.parse(_decrypted.toString(enc.Utf8)));

        if (linked_id) {
          this.filter["linked-id"] = linked_id;
        }
      }
    }
  },
  beforeDestroy: function beforeDestroy() {
    EventBus.$off("start-search");
    EventBus.$off("date-search");
    EventBus.$off("refresh-listing");
    this.$store.dispatch(SEARCH_ENABLED, false);
    this.$store.dispatch(SEARCH_TITLE, null);
  },
  beforeMount: function beforeMount() {
    this.init_linked();
  },
  mounted: function mounted() {
    var _this = this;

    _this.get_rows();

    _this.$store.dispatch(SEARCH_ENABLED, true);

    _this.$store.dispatch(SEARCH_TITLE, "Drive");

    _this.$store.dispatch(SET_BREADCRUMB, [{
      dashboard: true,
      disabled: false,
      id: 0,
      text: "Tag",
      uuid: null
    }]);

    EventBus.$on("refresh-listing", function () {
      _this.$nextTick(function () {
        _this.get_rows();
      });
    });
    EventBus.$on("date-search", function (param) {
      _this.filter["start-date"] = head(param);
      _this.filter["end-date"] = last(param);

      _this.get_rows();
    });
    EventBus.$on("start-search", function (param) {
      _this.filter.search = param;

      _this.$nextTick(function () {
        _this.get_rows();
      });
    });
  }
};