import _objectSpread from "D:/Development/Laravel/genic-drive-updated/frontend/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from "vuex";
import Dialog from "@/view/components/Dialog";
import TagMixin from "@/core/mixins/tag.mixin";
import TextInput from "@/view/components/TextInput";
import TextArea from "@/view/components/TextAreaInput";
import { POST, PATCH } from "@/core/services/store/request.module";
import { EventBus } from "@/core/event-bus/event.bus";
import { ERROR, MESSAGE } from "@/core/services/store/common.module";
export default {
  mixins: [TagMixin],
  data: function data() {
    return {
      endpoint: "tag",
      createDialog: false,
      editDialog: false,
      tag_name: null,
      tag_id: null,
      item_color: "#0dd3d3",
      colors: ["#0dd3d3", "#e2b910", "#61bfc1", "#f28840", "#d359aa", "#83b727", "#b58a61", "#ffac14", "#48b7b4", "#f78b84", "#57c065", "#018ecd", "#0995ba", "#33c5f2", "#ff65cb", "#b79d7d", "#3b92ff", "#ba83f2", "#d3854a", "#99a8bd"]
    };
  },
  methods: {
    createTag: function createTag() {
      this.createDialog = true;
      this.tag_name = null;
      this.tag_id = null;
      this.init_color();
    },
    editTag: function editTag() {
      var _this = this;

      this.item_color = this.temp_file.tag_color;
      this.tag_name = this.temp_file.tag_name;
      this.tag_id = this.temp_file.uuid;
      this.$nextTick(function () {
        _this.createDialog = true;
      });
    },
    init_color: function init_color() {
      this.item_color = this.colors[Math.floor(Math.random() * this.colors.length)];
    },
    save_tag: function save_tag() {
      var _this2 = this;

      if (!this.tag_name) {
        this.$store.dispatch(ERROR, [{
          model: true,
          message: "Tag name is required .",
          timeout: 2000
        }]);
        return false;
      }

      if (!this.item_color) {
        this.$store.dispatch(ERROR, [{
          model: true,
          message: "Tag color is required .",
          timeout: 2000
        }]);
        return false;
      }

      this.loading = true;
      var requestURL = "tags";
      var requestType = POST;

      if (this.tag_id) {
        requestURL = "tags/".concat(this.tag_id);
        requestType = PATCH;
      }

      this.$store.dispatch(requestType, {
        url: requestURL,
        data: {
          tag_name: this.tag_name,
          item_color: this.item_color
        }
      }).then(function () {
        _this2.createDialog = false;
        _this2.tag_name = null;
        _this2.tag_id = null;

        _this2.init_color();

        _this2.$store.dispatch(MESSAGE, [{
          model: true,
          message: "Success.",
          timeout: 2000
        }]);

        _this2.loading = false;

        _this2.$nextTick(function () {
          EventBus.$emit("refresh-listing", true);
          EventBus.$emit("refresh-storage", true);
        });
      }).catch(function (error) {
        console.log({
          error: error
        });
      }).finally(function () {
        _this2.loading = false;
      });
    },
    select_color: function select_color(param) {
      this.item_color = param;
    }
  },
  components: {
    TextArea: TextArea,
    TextInput: TextInput,
    Dialog: Dialog
  },
  computed: _objectSpread({}, mapGetters(["breadcrumbs"]))
};